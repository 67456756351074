<template>

    <div class="dark:bg-gray-900 bg-white">
        <div class="pt-24 lg:container mx-auto">
            <div class="mx-auto  ">
                <h1 class="text-5xl mx-3 mb-7 dark:text-white text-black ">Филиалы</h1>
                <BranchSelector :current="selected"></BranchSelector>
            </div>

            <div>
                <Head :items="head"></Head>
                <Departments :items="depts"></Departments>
            </div>
        </div>

    </div>
</template>

<script>
import BranchSelector from '@/components/branches/Selector'
import Head from '@/components/branches/Head'
import Departments from '@/components/branches/Departments'
import axios from "axios";

export default {
    name: "Branches",
    meta:{
        title:"Филиалы"
    },
    components: {
        BranchSelector, Head, Departments
    },
    data: () => ({
        selected: '',
        head: [],
        depts: [],
    }),
    created() {
        this.selected = this.$route.params.code
        this.fetch()
    },
    watch:{
        $route(){
            this.selected = this.$route.params.code
            this.fetch()
        }
    },
    methods: {
        fetch() {
            axios.get(`https://declarant.by/rest/branches/${this.selected}`)
                .then(res => {
                    this.head = res.data.data.head
                    this.depts = res.data.data.depts
                })
        }

    }

}
</script>

<style scoped>

</style>