<template>

    <div class="lg:container mx-auto">
        <div
            class="flex flex-wrap sm:justify-between lg:justify-around dark:bg-gray-800 bg-white shadow-xl dark:shadow-none mx-3 my-10 rounded-md">

            <router-link tag="a" :key="idx" v-for="(item,idx) in items" :class="{'text-green-400 ':item.value === current, 'dark:text-white' :item.value !== current}"
                    class="p-5 inline-flex  items-center text-gray-700 active hover:text-blue-500 dark:hover:text-green-300"
                    :to="{name:'Branches', params:{code:item.value}}">
                {{ item.name }}
            </router-link>
            <a href="http://www.beltamojizdat.by/" class="p-5 inline-flex items-center dark:text-white text-gray-700 active hover:text-blue-500 dark:hover:text-green-300"
               target="_blank" rel="nofollow">Белтаможиздат</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "Selector",
    data: () => ({
        items: [
            {name:'Брест', value:'brest'},
            {name:'Витебск', value:'vitebsk'},
            {name:'Гомель', value:'gomel'},
            {name:'Гродно', value:'grodno'},
            {name:'Минск', value:'minsk'},
            {name:'Могилев', value:'mogilev'},
        ]
    }),

    props:{
        current:{
            type:String,
            required:true
        }
    },
    methods: {
        setCategory() {
            return 0
        }
    }
}
</script>

<style scoped>

</style>