<template>
    <div class="pb-20 mx-3">
        <div v-for="(value, name, idx) in items" :key="'block'+idx" class="cursor-pointer dark:text-gray-300 text-black dark:bg-gray-800
         bg-white shadow-md rounded dark:shadow-none w-full  py-5 px-7 mt-5"
             :class="{'dark:hover:bg-gray-700': selected !== name}"
             @click="open(name, $event)"
        >
            <h2 class="font-semibold ">{{ name }}</h2>
            <transition name="slide">
                <div v-show="selected === name"
                     class=" text-left gap-x-1 overflow-hidden dark:bg-gray-800 bg-white border-l-2 bg-white border-indigo-500 leading-normal my-5">
                    <div class="flex sm:flex-col lg:flex-row mb-3" v-for="i in value" :key="i.id">
                        <div class="sm:w-full lg:w-1/5 lg:pl-4 sm:pl-2 pr-2 sm:my-1 lg:my-0">
                            <p v-if="i.name" class="font-semibold ">{{ i.name }}</p>
                            <p v-if="i.fio" class="">{{ i.fio }}</p>
                        </div>
                        <div class="sm:w-full lg:w-1/5  px-2 sm:my-1 lg:my-0">
                            <p v-for="(el,idx) in i.phone" :key="'phone'+idx">{{ el }}</p>
                        </div>
                        <div class="sm:w-full lg:w-1/5  px-2 sm:my-1 lg:my-0">
                            <p v-for="(el,idx) in i.mail" :key="'email'+idx" class="break-words">{{ el }}</p>
                        </div>
                        <div class="sm:w-full lg:w-1/5 px-2 sm:my-1 lg:my-0">
                            <p v-html="i.preview_text_html"></p>
                        </div>
                        <div class="sm:w-full lg:w-1/5 px-2 sm:my-1 lg:my-0">
                            <p>
                                {{ i.work }}<br>
                                <span v-if="i.lunch">Обед: {{ i.lunch }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="px-4 mt-5 "   v-if="name === 'ТЛЦ «Минск-Белтаможсервис»' || name === 'ТЛЦ «Минск-Белтаможсервис-2»'">
                        <h3 class="font-semibold mb-2 text-green-400">Документы для скачивания</h3>
                        <div v-if="name === 'ТЛЦ «Минск-Белтаможсервис»'">
                            <a class="block hover:text-blue-400 mb-1" :href="link.link" :key="'tlc1-'+idx" v-for="(link,idx) in tlc1" target="_blank" >
                                <i v-if="link.type ==='docx'" class="fas fa-file-word"></i>
                                <i v-else class="fas fa-file-pdf"></i>
                                {{link.title}}</a>
                        </div>
                        <div v-if="name === 'ТЛЦ «Минск-Белтаможсервис-2»'">
                            <a class="block hover:text-blue-400 mb-1" :href="link.link" :key="'tlc2-'+idx" v-for="(link,idx) in tlc2" target="_blank" >
                                <i v-if="link.type ==='docx'" class="fas fa-file-word"></i>
                                <i v-else class="fas fa-file-pdf"></i>
                                {{link.title}}</a>
                        </div>
                        <div v-if="name === 'ТЛЦ «Минск-Белтаможсервис»' || name === 'ТЛЦ «Минск-Белтаможсервис-2»'">
                            <a  class="block hover:text-blue-400 mb-1" :href="link.link" :key="'common'+idx" v-for="(link,idx) in common" target="_blank" >
                                <i v-if="link.type ==='docx'" class="fas fa-file-word"></i>
                                <i v-else class="fas fa-file-pdf"></i>
                                {{link.title}}</a>
                        </div>

                    </div>

                </div>
            </transition>

        </div>

    </div>
</template>

<script>
export default {
    name: "Departments",
    data: () => ({
        selected: null,
        tlc1:[
            {link:'https://declarant.by/upload/logistics/algoritm_pribytie_avto_na_pts_06649.pdf',title:'Алгоритм действий для физических лиц при оформлении автомобилей', type:'pdf'},
            {link:'https://declarant.by/upload/iblock/b49/ZAYAVKA-NA-RAZRUZKU-NA-SKLAD-TLTS-_Minsk_Beltamozhservis_1.docx',title:'Заявление на выгрузку', type:'docx'}
        ],
        tlc2:[
            {link:'https://declarant.by/upload/logistics/algoritm_pribytie_avto_na_pts_06651.docx',title:'Алгоритм действий для физических лиц при оформлении автомобилей', type:'docx'},
            {link:'https://declarant.by/upload/iblock/b49/ZAYAVKA-NA-RAZRUZKU-NA-SKLAD-TLTS-_Minsk_Beltamozhservis_2.docx',title:'Заявление на выгрузку', type:'docx'}
        ],
        common:[
            {link:'https://declarant.by/upload/прайсы складов/ТЛЦ Минск.pdf',title:'Прейскурант тарифов на услуги',type:'pdf'},
            {link:'https://declarant.by/upload/logistics/dog_hran_fiz.docx',title:'Договор хранения, физическое лицо', type:'docx'},
            {link:'https://declarant.by/upload/logistics/dog_por_fiz.docx',title:' Договор поручения, физическое лицо', type:'docx'},
            {link:'https://declarant.by/upload/logistics/dog_hran_na_ts_ur.docx',title:'Договор хранения на таможенном складе', type:'docx'},
            {link:'https://declarant.by/upload/logistics/dog_hran_na_sklade_sop_ur.docx',title:' Договор хранения на СОП', type:'docx'},
            {link:'https://declarant.by/upload/logistics/dog_poruch_ur.docx',title:'Договор поручения', type:'docx'},
            {link:'https://declarant.by/upload/logistics/dog_hran_na_svh_ur.docx',title:' Договор хранения на СВХ', type:'docx'},

        ]
    }),
    props: {
        items: {
            required: true,
        }
    },
    methods: {
        open(name, event) {
            if(event.target.tagName !== 'A'){
                if (name === this.selected) {
                    return this.selected = null
                }
                this.selected = name
            }
        }
    }
}
</script>

<style scoped>
.slide-enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 500px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0px;
}
</style>