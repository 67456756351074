<template>
    <div class="flex sm:flex-col lg:flex-row pb-10 flex-wrap " >
        <div v-for="item in items" :key="item.id" class=" sm:w-full lg:w-1/3 my-3 dark:text-white text-gray-700">
            <div class="m-3 py-3 px-7 dark:bg-gray-800 bg-white rounded-md h-full flex flex-col justify-center shadow-xl dark:shadow-none">
                <div class="flex justify-center">
                    <img class="h-68"
                         :src="item.image" alt="Белтаможсервис">
                </div>
                <div class="text-box mb-2">
                    <h3 class="text-lg mt-2 mb-4 font-semibold">{{ item.position }}</h3>
                    <h3 class="text-lg mt-2 mb-4">{{ item.name }}</h3>


                    <p v-for="(i, idx) in item.phones" :key="'phone-'+idx">{{ i }}</p>


                    <p v-for="(i, idx) in item.emails" :key="'email-'+idx">{{ i }}</p>

                    <p class="my-5" v-html="item.preview_text_html"></p>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Head",
    props: {
        items: {
            required: true,
            type: Array
        }
    }
}
</script>

<style scoped>

</style>